/*
DIGIVIESTI.fi / VÄRIT JA FONTIT:

Tekstit ja painikkeet:
Purppura: #481C7F
Musta: #000000

Fontti: Leipäteksti ja pikku-otsikot: Open Sans / Regular tai bold
https://fonts.google.com/specimen/Open+Sans
Fontti: Otsikot ja nimikkeet: DM Serif Display
https://fonts.google.com/specimen/DM+Serif+Display

PAINIKKEET:
Purppura: #481C7F
Oranssi: #F89300
Vihreä: #9ED800
Harmaa: #D5D5D5

FORMIT:
Reunaviiva: #E2E2E2
Fontti, ”Koodisi”: #797979 (Mobiilinäkymässä)
Täyttöväri: #F8F7F5 (Mobiilinäkymässä)
Painikkeen teksti, tumma: #333333 (Mobiilinäkymässä)
Painikkeen teksti, vaalea #FFFFFF (Mobiilinäkymässä)

Täyttöväri: #EAEAEA (Admin, lukittu lomakenttä)
Reunaväri: #707070 (Admin)
TILAPAINIKKEET: (Kampanjat, mallipohjat...)

Pinkki: #F1A1AA
Harmaa: #393939
Vihreä: #A9D15E
Vaalea harmaa: #C4C4C4
Punainen: #F74524
 */
@import url('https://fonts.googleapis.com/css?family=DM+Serif+Display|Open+Sans');

$font-family-headers: 'DM Serif Display', 'Open Sans', sans-serif;
$font-family-base: 'Open Sans', sans-serif;

$dvfi-purple: #481c7f;
$dvfi-orange: #f89300;
$dvfi-green: #9ed800;
$dvfi-grey: #d5d5d5;

$primary: $dvfi-purple;
$warning: $dvfi-orange;
$secondary: $dvfi-grey;
$success: $dvfi-green;

h3,
h2,
h1 {
  color: $dvfi-purple;
  font-family: $font-family-headers;
}

h1[id^='top-header'] {
  margin-top: 100px;
}

#timer-box {
  background-color: $dvfi-green;
  color: white;
  font-weight: bolder;
}

.login {
  text-align: center;
  max-width: 450px;

  h3 {
    text-transform: uppercase;
  }
}

.header {
  .logo img {
    max-height: 50px;
  }

  a {
    color: $dvfi-purple;
    text-decoration: none;
  }

  color: $dvfi-purple;
}

.bg-black {
  background-color: black !important;
}

.header-buttons {
  margin-top: 3rem;

  img {
    max-height: 60px;
  }

  a {
    font-family: $font-family-headers;
    text-decoration: none;
    font-size: 30px;
    color: black;
    vertical-align: middle;
  }
}

.fullscreen-form {
  @extend .container-sm;
  padding-top: 80px;
}

#coupon {
  .row {
    margin: 1rem;
  }
}

.campaignForm,
.userForm,
.groupForm,
.templateForm {
  .image-preview {
    max-width: 350px;
    max-height: 80px;
    width: auto;
    height: auto;
  }

  label {
    font-weight: bold;
  }

  .row {
    margin: 30px;

    h1,
    h2 {
      text-transform: uppercase;
    }

    h3,
    h2,
    h1 {
      text-align: center;
    }

    h3 {
      color: black;
    }

    .calendar {
      z-index: 1;
    }
  }

  .modal-footer {
    justify-content: space-between;
  }

  .group-table {
    td {
      .btn-link {
        font-size: inherit;
        padding: 1px;
      }
    }
  }
}

.pagination {
  .page-item {
    cursor: pointer;

    .active {
      .page-link {
        color: white;
        background-color: $dvfi-purple;
      }
    }
  }
}

.title {
  margin-top: 1rem;
    margin-bottom: 1rem;
}

.card-title {
  i {
    font-size: 7em;
  }
}

#couponPreviewModal {
}

.coupon#coupon {
  max-width: 400px;

  .qrcode .logo .banner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: auto;
  }

  .logo {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .info {
    font-weight: bolder;
    text-transform: uppercase;
    overflow-wrap: anywhere;
  }

  > div > button {
    height: 50px;
  }
}

.select-search#groupSelect {
  width: unset;
  z-index: 0;
}

.footer {
  img {
    display: block;
    max-height: 20px;
    width: auto;
    height: auto;
    margin: 20px;
  }
}

.reporting-used-amount-col {
  background-color: #ebebeb;
}

.reporting-unused-amount-col {
  background-color: #f1fdeb;
}

.sms-message {
  background-color: #4c72f3;
  color: white;
}

.layout-img {
  max-width: 250px;
}

span.gsmwarning {
  color: red;
  font-weight: bold;
}